import { Controller } from "stimulus"
import { spinner } from "../fetch_helpers/spinner"

export default class extends Controller {
	static targets = ["card", "personName", "cardError", "submitButton", "activityIndicator"]
	connect() {
		this.stripe = Stripe(`${this.data.get('pk')}`,
			{ stripeAccount: `${this.data.get('accountToken')}` }
		);
		this.clientSecret = `${this.data.get('clientSecret')}`
		const elements = this.stripe.elements();
		
		const style = {
		  base: {
		    color: '#32325d',
		    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
		    fontSmoothing: 'antialiased',
		    fontSize: '16px',
		    '::placeholder': {
		      color: '#aab7c4'
		    }
		  },
		  invalid: {
		    color: '#fa755a',
		    iconColor: '#fa755a'
		  }
		}
		
		this.card = elements.create('card', { style: style })
		
		this.card.mount(this.cardTarget)
		
		const cardErrorEl = this.cardErrorTarget
		
		this.card.addEventListener("change", function(e) {
		  if (e.error) {
		    cardErrorEl.textContent = e.error.message;
		  } else {
		    cardErrorEl.textContent = '';
		  }
		})
	}
	
	submit(e) {
		const reservationEl = this.element
		const submitButton = this.submitButtonTarget
		const submitButtonText = submitButton.textContent
		const cardErrorEl = this.cardErrorTarget
		reservationEl.classList.add("reservation-checkout-final--disabled")
		submitButton.disabled = true
		submitButton.innerHTML = spinner()
	  this.stripe.confirmCardPayment(this.clientSecret, {
	    payment_method: {
	      card: this.card,
	      billing_details: {
	        name: this.personNameTarget.textContent
	      }
	    }
	  }).then(function(result) {
	    if (result.error) {
				cardErrorEl.textContent = `${result.error.message} Please reload the page and try again.`
				reservationEl.classList.remove("reservation-checkout-final--disabled")
				submitButton.disabled = false
				submitButton.innerHTML = submitButtonText
	    } else {
	      if (result.paymentIntent.status === 'succeeded') {
					window.location.href = `${window.location.href}/confirmed?payment_intent_id=${result.paymentIntent.id}`
	      } else {
					cardErrorEl.textContent = "There was an error processing your payment. Please try again with a different payment method."
					reservationEl.classList.remove("reservation-checkout-final--disabled")
					submitButton.disabled = false
					submitButton.innerHTML = submitButtonText
	      }
	    }
	  })
	}
}