import { Controller } from "stimulus"
import { fire } from "@rails/ujs"

export default class extends Controller {

	book(e) {
		e.preventDefault()
		const resp = e.detail[0]
		if (resp.status === "OK") {
			fire(document.querySelector(".close"), "click")
			const reservationsEl = document.querySelector('[data-reservations]')
			const footer = document.querySelector(".reservation-footer")
			reservationsEl.innerHTML = resp.html
			if (!document.querySelector("[data-target='confirm-reservation']")) {
				reservationsEl.insertAdjacentHTML("afterend", resp.action)
			}
			if (footer) {
				footer.classList.remove("reservation-footer--closed")
			}
		} else {
			this.failedToBook(e)
		}
	}
	
	failedToBook(e) {
		e.preventDefault()
		alert("booking failed")
	}
	
	removeFailed(e) {
		e.preventDefault()
		alert("removal failed")
	}
	
	remove(e) {
		e.preventDefault()
		const resp = e.detail[0]
		if (resp.status === "OK") {
			fire(document.querySelector(".close"), "click")
			document.querySelector('[data-reservations]').innerHTML = resp.html
		} else {
			removeFailed(e)
		}
	}
	
}