import { Controller } from "stimulus"
import { fire } from "@rails/ujs"

export default class extends Controller {
	static targets = ["content"]
	
	close(e) {
		this.contentTarget.innerHTML = ""
		this.element.classList.remove("open")
		e.preventDefault()
	}
	
	handleSubmit(e) {
		const result = e.detail[0]
		if (result.status === "OK") {
			let grids = document.querySelectorAll(`[data-grid-id]`)
			grids.forEach(function(grid) { fire(grid, "renderGrid" ) })
			this.close(e)
		} else if (result.status === "Destroyed") {
			let grid = document.querySelector(`[data-grid-id='${result.grid_id}']`)
			grid.remove()
			this.close(e)
		} else {
			alert(result.message)
		}
	}
}