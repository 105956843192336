import consumer from "./consumer"

export function listenForReservation(reservation_id) {
	consumer.subscriptions.create(
		{ 
			channel: "ReservationChannel", 
			id: reservation_id
		},
		{
		  connected() {
		    // Called when the subscription is ready for use on the server
		  },

		  disconnected() {
		    // Called when the subscription has been terminated by the server
		  },

		  received(data) {
				let reservations = document.querySelector(`[data-reservations]`)
				reservations.innerHTML = data
		  }
		}
	)
}