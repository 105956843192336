import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "countdown" ]
	
	connect() {
		const endTime = Date.parse(this.data.get("end"))
		let el = this.element
		const footer = document.querySelector(".reservation-footer")
		let countdownEl = this.countdownTarget
		this.countdown = setInterval(function() {
			const diffInMilliseconds = endTime - Date.now()
			const diffInSeconds = diffInMilliseconds / 1000
			const diffInMinutes = parseInt(diffInSeconds / 60)
			const remainingSeconds = parseInt(diffInSeconds % 60)
			if (diffInMinutes < 1 && diffInSeconds < 1) {
				el.innerText = "Released"
				if (footer) {
					footer.classList.add("reservation-footer--closed")
				}
			} else if (diffInMinutes < 1) {
				countdownEl.innerText = `${remainingSeconds} seconds`
			} else {
				countdownEl.innerText = `${diffInMinutes + 1} minutes`
			}
		}, 1000);
	}
	
	disconnect() {
		clearInterval(this.countdown)
	}
}