import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["text", "button"]
	
  copy(e) {
    this.textTarget.select()
    document.execCommand("copy")
		
		const el = this.buttonTarget
		const text = el.textContent
		el.textContent = "Copied!"
		
		setTimeout(() => el.textContent = text, 3000)
  }
}