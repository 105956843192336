import { fabric } from "fabric"

export function drawLines(canvas, gridSize, canvasWidth, canvasHeight) {
	const strokeColor = 'rgba(190, 190, 190, 0.45)'
	const options = { stroke: strokeColor, selectable: false }
	for (let i = 0; i < (canvasWidth / gridSize); i++) {
	  canvas.add(new fabric.Line([ i * gridSize, 0, i * gridSize, canvasHeight], options))
	}

	for (let i = 0; i < (canvasHeight / gridSize); i++) {
	  canvas.add(new fabric.Line([ 0, i * gridSize, canvasWidth, i * gridSize], options))
	}
	// last line, why the canvas is one pixel larger in each direction
	canvas.add(new fabric.Line([canvasWidth, 0, canvasWidth, canvasHeight], options))
	canvas.add(new fabric.Line([0, canvasHeight, canvasWidth, canvasHeight], options))
}