export function fetchOptions() {
	const options = {
		'credentials': 'same-origin',
		'headers': {
			'X-Requested-With': 'XMLHttpRequest',
			'Accept': 'application/json',
			'Accept-Encoding': 'gzip'
		}
	}
	return options
}

export function patchOptions(params) {
	let options = fetchOptions()
	options["body"] = params
	options["method"] = "PATCH"
	options["headers"]["X-CSRF-Token"] = document.querySelector("meta[name='csrf-token']").content
	return options
}

export function htmlOptions() {
	let options = fetchOptions()
	options["headers"]["Accept"] = "text/html"
	return options
}