import consumer from "./consumer"
import { fire } from "@rails/ujs"

export function listenForGrid(grid_id, grid_type) {
	consumer.subscriptions.create(
		{ 
			channel: "GridChannel", 
			id: grid_id
		},
		{
		  connected() {
		    // Called when the subscription is ready for use on the server
		  },

		  disconnected() {
		    // Called when the subscription has been terminated by the server
		  },

		  received(data) {
				let grid = document.querySelector(`[data-${grid_type}-id='${grid_id}']`)
				if (grid) {
					fire(grid, "updateGrid", data)
				}
		  }
		}
	)
}