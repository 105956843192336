import { fabric } from "fabric"

export function calculateDrag(canvas, canvasWidth, canvasHeight, evt, lastX, lastY) {
	let currentX = 0
	let currentY = 0
	if (evt.touches) {
		let touch = evt.touches[0]
    currentX = touch.screenX
    currentY = touch.screenY
	} else {
		currentX = evt.layerX
		currentY = evt.layerY
	}
  let xChange = currentX - lastX
  let yChange = currentY - lastY
	
	const viewX = canvas.viewportTransform[4]
	const viewY = canvas.viewportTransform[5]
	
	const totalCanvasWidth = canvas.getWidth()
	const heightBoundary = canvasHeight - 25
	const rightBoundary = totalCanvasWidth - 25
	const leftBoundary = rightBoundary - (totalCanvasWidth - canvasWidth)
	
  if ((Math.abs(currentX - lastX) <= 50) && (Math.abs(currentY - lastY) <= 50)) {
		if ((viewX > rightBoundary && xChange > 0) || (viewX < leftBoundary * -1 && xChange < 0)) {
			xChange = 0
		}
		if ((viewY > heightBoundary && yChange > 0) || (viewY < heightBoundary * - 1 && yChange < 0)) {
			yChange = 0
		}
		let delta = new fabric.Point(xChange, yChange);
		canvas.relativePan(delta);
  }
	
	return [currentX, currentY]
}