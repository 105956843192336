import { Controller } from "stimulus"
import { fabric } from "fabric"
import { drawLines } from "../grid_helpers/draw_lines"
import { buildCanvas } from "../grid_helpers/build_canvas"
import { centerZoom } from "../grid_helpers/zoom"
// import { mouseWheel } from "../grid_helpers/mouse_wheel"
import { calculateDrag } from "../grid_helpers/calculate_drag"
import { fillModal } from "../helpers/fill_modal"
import { fetchOptions } from "../fetch_helpers/fetch_options"
import { listenForGrid } from "../channels/grid_channel"
import { debounce } from "../grid_helpers/debounce"

export default class extends Controller {
  static targets = [ "gridContainer" ]
	
  connect() {
		this.setupCanvas()
		listenForGrid(this.data.get('id'), "buyer-grid")
		const _this = this
		window.addEventListener("resize", debounce(this.resetupCanvas.bind(_this), 250))
	}
	
	// TODO: Remove this method or use it to fix things after a window resize
	resetupCanvas() {
		this.teardown()
		this.setupCanvas()
	}
	
	teardown() {
		// prevent turbolinks from caching the canvas and doubling it
		this.canvas.clear()
		this.canvas.dispose()
		this.element.querySelectorAll('canvas').forEach(function(el) { el.remove() })
	}
	
	disconnect() {
		this.canvas = null
		const _this = this
		window.removeEventListener("resize", debounce(this.resetupCanvas.bind(_this), 250))
	}
	
	update(e) {
		const data = e.detail
		let currentPersonId = null
		let currentPersonEl = document.querySelector("[data-current-person-id]")
		if (currentPersonEl) {
			currentPersonId = currentPersonEl.dataset.currentPersonId
		}
		this.canvas.getObjects().forEach(function(obj) {
			if (data[obj.id] !== undefined) {
				let box = obj._objects[0]
				if (data[obj.id] !== null) {
					const isOwner = currentPersonId == data[obj.id]
					obj.set('editable', isOwner)
					obj.set('hoverCursor', isOwner ? 'pointer' : 'not-allowed')
					box.set('fill', isOwner ? '#B2E749' : '#AAAAAA')
				} else if (data[obj.id] === null) {
					obj.set('editable', true)
					obj.set('hoverCursor', 'pointer')
					box.set('fill', '#E88C1C')
				}
			}
		})
		this.canvas.renderAll()
	}
	
	setupCanvas() {
		let canvasEl = buildCanvas(this)
		this.canvas = new fabric.Canvas(canvasEl.id, { selection: false })
		let canvas = this.canvas
		let _this = this
		// let activityIndicator = this.activityIndicatorTarget
		// let activityTimeout = null
		
		const gridSize = this.data.get('boxSize')
		const canvasWidth = parseInt(this.data.get('width')) - 1
		const canvasHeight = parseInt(this.data.get('length')) - 1
		// const extraCanvasJson = ['hasRotatingPoint', 'hasBorders', 'hasControls', 'hoverCursor', 'id', 'selectable', 'url', 'editable']
		
		fetch(this.data.get("url"), fetchOptions()).then(function(response) {
			return response.json()
		}).then(function(json) {
			canvas.loadFromJSON(json, canvas.renderAll.bind(canvas))
			
			drawLines(canvas, gridSize, canvasWidth, canvasHeight)
			
			if (canvas.getWidth() > canvasWidth) {
				canvas.relativePan({ x: (canvas.getWidth() - canvasWidth) / 2, y: 0 })
			}
			
			let zoomStartScale = canvas.getZoom()
			let isDragging = false
			// let isPinching = false
			
			// canvas.on('mouse:wheel', mouseWheel)
			
			let lastX = 0
			let lastY = 0
			
			canvas.on('touch:drag', function(options) {
				if (options.self.state === "move") {
					isDragging = true
					let evt = options.e
	        const result = calculateDrag(canvas, canvasWidth, canvasHeight, evt, lastX, lastY)
					lastX = result[0]
					lastY = result[1]
				} else if (options.self.state === "up") {
					isDragging = false
				}
			})
			
			// canvas.on('touch:gesture', function(options) {
			// 	if (options.e.touches && options.e.touches.length === 2) {
			// 		if (options.self.state === "start") {
			// 			zoomStartScale = canvas.getZoom()
			// 		}
			// 		let point = new fabric.Point(options.self.x, options.self.y);
			// 		var delta = zoomStartScale * options.self.scale;
			// 		canvas.zoomToPoint(point, delta);
			// 		options.e.preventDefault()
			// 		options.e.stopPropagation()
			// 	}
			// });
						
			// create a clone in case of a server rejection to reload
			// let clonedCanvas = canvas.toJSON(extraCanvasJson)
			
			canvas.on('mouse:up', function(options) {
				if (isDragging) {
					isDragging = false
					return isDragging
				}
				const booth = options.target
				if (booth && booth.id && booth.editable) {
					fillModal(booth.url)
				}
			})
		})
  }
	
	zoomIn(e) {
		centerZoom(this.canvas, 0.25)
		e.preventDefault()
	}
	
	zoomOut(e) {
		centerZoom(this.canvas, -0.25)
		e.preventDefault()
	}
	
}