import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [""]
	
	fill(e) {
		const result = e.detail[0]
		const form = result.querySelector('[data-target="modal-content"]')
		const modal = document.querySelector("[data-controller='grid-modal']")
		const modalContent = modal.querySelector("[data-target='grid-modal.content']")
		modalContent.innerHTML = ""
		modalContent.appendChild(form)
		modal.classList.add('open')
	}
}