import { Controller } from "stimulus"
import { fabric } from "fabric"
import { drawLines } from "../grid_helpers/draw_lines"
import { buildCanvas } from "../grid_helpers/build_canvas"
import { centerZoom } from "../grid_helpers/zoom"
// import { mouseWheel } from "../grid_helpers/mouse_wheel"
import { calculateDrag } from "../grid_helpers/calculate_drag"
import { fillModal } from "../helpers/fill_modal"
import { fetchOptions, patchOptions } from "../fetch_helpers/fetch_options"
import { spinner } from "../fetch_helpers/spinner"
import { listenForGrid } from "../channels/grid_channel"
import { debounce } from "../grid_helpers/debounce"

export default class extends Controller {
  static targets = [ "activityIndicator", "gridContainer" ]

  connect() {
		this.setupCanvas()
		listenForGrid(this.data.get('id'), "grid")
		const _this = this
		window.addEventListener("resize", debounce(this.resetupCanvas.bind(_this), 250))
	}
	
	// TODO: Remove this method or use it to fix things after a window resize
	resetupCanvas() {
		this.teardown()
		this.setupCanvas()
	}
	
	teardown() {
		// prevent turbolinks from caching the canvas and doubling it
		this.canvas.clear()
		this.canvas.dispose()
		this.element.querySelectorAll('canvas').forEach(function(el) { el.remove() })
	}
	
	update(e) {
		const data = e.detail
		this.canvas.getObjects().forEach(function(obj) {
			if (data[obj.id] !== undefined) {
				let box = obj._objects[0]
				if (data[obj.id] !== null) {
					box.set('fill', '#AAAAAA')
				} else if (data[obj.id] === null) {
					box.set('fill', '#E88C1C')
				}
			}
		})
		this.canvas.renderAll()
	}
	
	disconnect() {
		this.canvas = null
		const _this = this
		window.removeEventListener("resize", debounce(this.resetupCanvas.bind(_this), 250))
	}
	
	setupCanvas() {
		let canvasEl = buildCanvas(this)
		this.canvas = new fabric.Canvas(canvasEl.id, { selection: false })
		let canvas = this.canvas
		let _this = this
		let activityIndicator = this.activityIndicatorTarget
		let activityTimeout = null
		
		const gridSize = this.data.get('boxSize')
		const canvasWidth = parseInt(this.data.get('width')) - 1
		const canvasHeight = parseInt(this.data.get('length')) - 1
		const extraCanvasJson = ['hasRotatingPoint', 'hasBorders', 'hasControls', 'hoverCursor', 'id', 'selectable', 'url', 'reserved']
		
		fetch(this.data.get("url"), fetchOptions()).then(function(response) {
			return response.json()
		}).then(function(json) {
			canvas.loadFromJSON(json, canvas.renderAll.bind(canvas))
			
			drawLines(canvas, gridSize, canvasWidth, canvasHeight)
			
			if (canvas.getWidth() > canvasWidth) {
				canvas.relativePan({ x: (canvas.getWidth() - canvasWidth) / 2, y: 0 })
			}
						
			// create a clone in case of a server rejection to reload
			let clonedCanvas = canvas.toJSON(extraCanvasJson)
			
			let isMoving = false
			// let isPinching = false
			let isDragging = false
			let lastX = 0
			let lastY = 0
			
			let zoomStartScale = canvas.getZoom()
			
			// canvas.on('mouse:wheel', mouseWheel)
			
			canvas.on('touch:drag', function(options) {
				if (!isMoving && options.self.state === "move") {
					isDragging = true
					let evt = options.e
      
	        const result = calculateDrag(canvas, canvasWidth, canvasHeight, evt, lastX, lastY)
					lastX = result[0]
					lastY = result[1]
				} else if (options.self.state === "up") {
					isDragging = false
				}
			})
			
			// canvas.on('touch:gesture', function(options) {
			// 	if (options.e.touches && options.e.touches.length === 2) {
			// 		if (options.self.state === "start") {
			// 			isPinching = true
			// 			canvas.getObjects().forEach(function(obj) {
			// 				obj.lockMovementX = true
			// 				obj.lockMovementY = true
			// 			})
			// 			zoomStartScale = canvas.getZoom()
			// 		}
			// 		let point = new fabric.Point(options.self.x, options.self.y);
			// 		var delta = zoomStartScale * options.self.scale;
			// 		canvas.zoomToPoint(point, delta);
			// 		options.e.preventDefault()
			// 		options.e.stopPropagation()
			// 	}
			// });
			
			canvas.on('object:selected', function(options) {
				isMoving = true
			});
			
			canvas.on('object:moving', function(options) {
				// prevent object from being dragged off canvas
				isMoving = true
				let oLeft = Math.round(options.target.left / gridSize) * gridSize
				let oTop = Math.round(options.target.top / gridSize) * gridSize
				const oHeight = options.target.height
				const oWidth = options.target.width
				if (oTop < 0) { oTop = 0 }
				if (oLeft < 0) { oLeft = 0 }
				if (oTop + oHeight >= canvasHeight) { oTop = canvasHeight - oHeight }
				if (oLeft + oWidth >= canvasWidth) { oLeft = canvasWidth - oWidth }
				// ensure we're on the grid lines
			  options.target.set({
			    left: oLeft,
			    top: oTop
			  })
			})
			
			// TODO: Optimization - only save when object moves from a previous position
			// TODO: consider object:modified as the proper event?
			// TODO: consider setting opacity or other indcator on movement: http://fabricjs.com/customization
			canvas.on('object:moved', function(options) {
				if (options.target) {
					clearTimeout(activityTimeout)
					activityIndicator.innerHTML = spinner()
					const booth = options.target
					let params = new FormData()
					params.append("booth[left]", booth.left)
					params.append("booth[top]", booth.top)
					fetch(booth.url, patchOptions(params)).then(function(response) {
						return response.json()
					}).then(function(result) {
						if (result["status"] === "OK") {
							activityIndicator.textContent = ""
							// keep a new copy of our saved canvas
							clonedCanvas = canvas.toJSON(extraCanvasJson)
						} else {
							activityIndicator.textContent = `Error: ${result["message"]}`
							// load our saved json canvas
							canvas.loadFromJSON(clonedCanvas, canvas.renderAll.bind(canvas))
						}
						activityTimeout = setTimeout(function() { activityIndicator.textContent = "" }, 3000)
					})
				}
			})
			
			canvas.on('mouse:up', function(options) {
				if (isMoving) {
					isMoving = false
					return isMoving
				}
				if (isDragging) {
					isDragging = false
					return isDragging
				}
				// if (isPinching) {
				// 	isPinching = false
				// 	canvas.getObjects().forEach(function(obj) {
				// 		obj.lockMovementX = false
				// 		obj.lockMovementY = false
				// 	})
				// 	return isPinching
				// }
				const booth = options.target
				if (booth && booth.id) {
					fillModal(booth.url)
				}
			})
		})
  }
	
	zoomIn(e) {
		centerZoom(this.canvas, 0.25)
		e.preventDefault()
	}
	
	zoomOut(e) {
		centerZoom(this.canvas, -0.25)
		e.preventDefault()
	}
	
}