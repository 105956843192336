import { fabric } from "fabric"

export function buildCanvas(controller) {
	const headroom = window.innerHeight - 72 // allow 50px plus a rem for header
	let canvasEl = document.createElement("canvas")
	const thisElStyle = window.getComputedStyle(controller.element)
	const width = parseInt(thisElStyle.width)
	let height = parseInt(controller.data.get('length'))
	if (height >= headroom) {
		height = headroom
	} else if (height < 200) {
		height = 200
	}
	canvasEl.id = controller.data.get('canvas')
	canvasEl.height = height
	canvasEl.width = width - 8
	controller.gridContainerTarget.insertBefore(canvasEl, null)
	return canvasEl
}