import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "total", "reservationAddons" ]
	
	updateTotal(e) {
		const result = e.detail[0]
		if (result.status === "OK") {
			this.reservationAddonsTarget.innerHTML = result.html
			this.totalTarget.innerText = result.total
		} else {
			alert("totalling failed")
		}
	}
	
}