import { htmlOptions } from "../fetch_helpers/fetch_options"

export function fillModal(url) {
	fetch(url, htmlOptions()).then(function(response) {
		return response.text()
	}).then(function(result) {
		let modal = document.querySelector("[data-controller='grid-modal']")
		modal.querySelector("[data-target='grid-modal.content']").innerHTML = result
		modal.classList.add('open')
	})
}